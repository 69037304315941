import $ from "jquery";

$(document).ready(
    function () {
        const $fileUploads = $('.background-job.type-file');

        const showErrorModal = function (message) {
            $('<p class="ui negative message"><i class="close icon"></i>' + message + '</div>').modal('show');
        };

        $fileUploads.click(
            function (e) {
                const $self = $(this);

                if ($(e.target).is('input')) {
                    return;
                }

                if ($self.hasClass('loading')) {
                    return;
                }

                const url = $self.data('url');
                const jobName = $self.data('job-name');
                const accept = $self.data('accept');

                if (false === $self.hasClass('initialized')) {
                    const $input = $('<input type="file"/>');

                    $input.addClass('upload-input');

                    $input.css('width', '0');
                    $input.css('height', '0');
                    $input.css('overflow', 'hidden');

                    if (accept) {
                        $input.attr('accept', accept);
                    }

                    $self.append($input);
                    $self.addClass('initialized');

                    $self.on(
                        'watchState',
                        function () {
                            const id = $self.data('job-id');

                            if (null === id) {
                                return;
                            }

                            const url = $self.data('status-url').replace('_id_', id);

                            let request = null;
                            let watcher = setInterval(
                                function () {
                                    if (request) {
                                        request.abort();
                                    }

                                    request = $.get(url)
                                        .done(
                                            function (response) {
                                                if ('pending' === response.state) {
                                                    return;
                                                }

                                                if ('running' === response.state) {
                                                    return;
                                                }

                                                let output = response.output || {};

                                                $self.data('job-error', output.error || null);
                                                $self.trigger('state', [response.state]);
                                                clearInterval(watcher);
                                            }
                                        )
                                        .fail(
                                            function () {
                                                clearInterval(watcher);
                                            }
                                        )
                                        .always(
                                            function () {
                                                request = null;
                                            }
                                        );
                                },
                                1500
                            );
                        }
                    );

                    // handle button state && classes
                    $self.on(
                        'state',
                        function (e, state) {
                            $self.data('job-state', state);

                            if ('pending' === state) {
                                $self.addClass('loading');
                                $self.addClass('disabled');

                                return;
                            }

                            $self.removeClass('loading');
                            $self.removeClass('disabled');
                        }
                    );

                    // display error
                    $self.on(
                        'state',
                        function (e, state) {
                            if ('failed' !== state) {
                                return;
                            }

                            showErrorModal($self.data('job-error') || 'Error occurred!');
                        }
                    );

                    // reload page on finished state
                    $self.on(
                        'state',
                        function (e, state) {
                            if ('finished' !== state || true !== $self.data('reload')) {
                                return;
                            }

                            window.location.reload(false);
                        }
                    );

                    $self.on(
                        'upload',
                        function () {
                            const formData = new FormData();

                            formData.append('name', jobName);

                            $($input[0].files).each(
                                function (index, file) {
                                    formData.append(index, file);
                                }
                            );

                            $self.trigger('state', ['pending']);

                            $.ajax(
                                {
                                    url: url,
                                    data: formData,
                                    cache: false,
                                    contentType: false,
                                    processData: false,
                                    method: 'POST',
                                    type: 'POST'
                                }
                            ).done(
                                function (response) {
                                    if (typeof response !== "object") {
                                        window.location.reload(false);

                                        return;
                                    }

                                    $self.data('job-id', response.id);
                                    $self.trigger('state', [response.state]);

                                    if ('pending' === response.state) {
                                        $self.trigger('watchState');
                                    }
                                }
                            ).fail(
                                function () {
                                    $self.trigger('state', ['failed']);
                                }
                            ).always(
                                function () {
                                    $input.val('');
                                }
                            );
                        }
                    );

                    $input.change(
                        function () {
                            if (null === $input.val()) {
                                return;
                            }

                            $self.trigger('upload');
                        }
                    );
                }

                const $fileInput = $self.find('.upload-input:first');

                $fileInput.trigger('click');
            }
        );
    }
);
