import 'semantic-ui-calendar/dist/calendar'

const formatDate = function(date) {
    const monthNumber = date.getMonth() + 1;
    const day = (date.getDate().toString().length === 1 ? '0' : '') + date.getDate();
    const month = (monthNumber.toString().length === 1 ? '0' : '') + monthNumber;
    const year = date.getFullYear();

    return [year, month, day].join('-');
};

const formatTime = function(date) {
    const hours = (date.getHours().toString().length === 1 ? '0' : '') + date.getHours();
    const minutes = (date.getMinutes().toString().length === 1 ? '0' : '') + date.getMinutes();

    return [hours, minutes].join(':');
};

export const calendarSettings = {
    ampm: false,
    startMode: 'year',
    formatter: {
        datetime: (date, settings) => {
            if (!date) {
                return '';
            }

            return formatDate(date) + (settings.type === 'datetime' ? ' ' + formatTime(date) : '');
        },
    }
};
