import 'semantic-ui-css/components/accordion';
import 'semantic-ui-css/components/checkbox';
import 'semantic-ui-css/components/dimmer';
import 'semantic-ui-css/components/dropdown';
import 'semantic-ui-css/components/rating';
import 'semantic-ui-css/components/sidebar';
import 'semantic-ui-css/components/tab';
import 'semantic-ui-css/components/transition';
import $ from 'jquery';

import 'sylius/ui/sylius-api-login'
import 'sylius/ui/sylius-api-toggle'
import 'sylius/ui/sylius-auto-complete'
import 'sylius/ui/sylius-bulk-action-require-confirmation'
import 'sylius/ui/sylius-form-collection'
import 'sylius/ui/sylius-prototype-handler'
import 'sylius/ui/sylius-require-confirmation'
import 'sylius/ui/sylius-toggle'
import './background_file_job';
import { calendarSettings } from './calendar';

$(document).ready(() => {
    // ### SYLIUS ###
    $('#sidebar').addClass('visible');
    $('#sidebar').sidebar('attach events', '#sidebar-toggle', 'toggle');
    $('#sidebar').sidebar('setting', {
        dimPage: false,
        closable: false,
    });

    $('.ui.checkbox').checkbox();
    $('.ui.accordion').accordion();
    $('.ui.menu .dropdown').dropdown({ action: 'hide' });
    $('.ui.inline.dropdown').dropdown();
    $('.link.ui.dropdown').dropdown({ action: 'hide' });
    $('.button.ui.dropdown').dropdown({ action: 'hide' });
    $('.ui.fluid.search.selection.ui.dropdown').dropdown();
    $('.ui.tabular.menu .item, .sylius-tabular-form .menu .item').tab();
    $('.ui.card .dimmable.image, .ui.cards .card .dimmable.image').dimmer({ on: 'hover' });
    $('.ui.rating').rating('disable');
    $('.ui.calendar.calendar-date').calendar({ type: 'date', ...calendarSettings });
    $('.ui.calendar.calendar-datetime-local').calendar({ type: 'datetime', ...calendarSettings });

    $('form.loadable').on('submit', (event) => {
        $(event.currentTarget).closest('form').addClass('loading');
    });
    $('.loadable.button').on('click', (event) => {
        $(event.currentTarget).addClass('loading');
    });
    $('.message .close').on('click', (event) => {
        $(event.currentTarget).closest('.message').transition('fade');
    });

    $('[data-requires-confirmation]').requireConfirmation();
    $('[data-bulk-action-requires-confirmation]').bulkActionRequireConfirmation();
    $('[data-toggles]').toggleElement();

    $('.special.cards .image').dimmer({
        on: 'hover',
    });

    $('[data-form-type="collection"]').CollectionForm();

    // ### CUSTOM ###
    $('[data-button="collectFormElements"]').on('click', (event) => {
        const el = $(event.target).closest('button');

        const row = el.closest('tr');
        const form = el.closest('form');

        if (0 === form.find('.data').length) {
            $('<div class="data" style="display: none;"></div>').appendTo(form);
        }

        const data = form.find('.data')[0];
        $(data).html('');

        row.find('input[data-form-name="'+ el.data('form-name') +'"]').each(function() {
            $(this).clone().appendTo(data);
        });
    });

    $('.ui.dropdown').dropdown();
});
